import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import React, { useState, useEffect } from "react";

export default function Home() {
  
const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const slides = ['/images/vasfa-main-1.png', '/images/vasfa-main-2.png', '/images/vasfa-main-3.png'];

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  return (
    <div className="mt-32 relative group w-full h-96">
      <div
        style={{ backgroundImage: `url(${slides[currentIndex]})` }}
        className="slide-img-home cursor-pointer"
        onClick={() => {
          setCurrentIndex(currentIndex);
        }}
      ></div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className="flex top-4 justify-center py-2">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl cursor-pointer"
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
};

  
  return (
    <div>
      <Header />
      <ImageSlider />

      <div className="home-page-about-container">
        <p className="home-page-about-title">Rövid cégismertető</p>
        <p className="home-page-about-text">
          Cégünk, a VASFA Szolnoki Kazángyártó Kft., ill. jogelődje közel hatvan
          éve alapíttatott és több mint negyven éve gyárt kazánokat és kazánházi
          berendezéseket. A cég az energetikai berendezések fejlesztése és
          gyártása területén fokozatosan fejlődött, gyártmánystruktúrája mára
          jelentősen bővült a gyártási feltételekkel összefüggő műszaki,
          technológiai fejlesztések eredményeként. A saját tervezésű, elismert
          minőségű berendezéseink elsősorban a hazai energetikai piacon
          meghatározóak, de az elmúlt években fokozatosan bővítettük export
          tevékenységünket is. A kazánokat, nyomástartó berendezéseket az EU-s
          szabványok és előírások szerint tervezzük, gyártjuk, vizsgáljuk,
          tanúsítjuk és CE jellel szállítjuk.
        </p>
        <p>
          <button className="home-page-about-button">
            <a href="/rolunk">Tudjon meg rólunk többet!</a>
          </button>
        </p>
      </div>
      <span className="technologies-container-title">
        Technológiai gőzfelhasználás VASFA berendezésekkel
      </span>
      <div class="technologies-container">
        <div className="technologies-container-left">
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-prescription-bottle-medical"></i>
            </span>
            <span>Gyógyszeripar</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-regular fa-hospital"></i>
            </span>
            <span>Egészségügyi intézmények</span>
          </div>
          <div className="technologies-container-element">
            <span className="material-icons">
            </span>
            <span>Konzervipar</span>
          </div>
          <div className="technologies-container-element">
            <span className="material-icons">
            </span>
            <span>Üdítőipar</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-utensils"></i>
            </span>
            <span>Élelmiszer előállítás</span>
          </div>
        </div>
        <div className="technologies-container-right">
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-car"></i>
            </span>
            <span>Autóipar</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-flask"></i>
            </span>
            <span>Vegyipar</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-wrench"></i>
            </span>
            <span>Gépipar</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-dumpster"></i>
            </span>
            <span>Hulladékfeldolgozás</span>
          </div>
          <div className="technologies-container-element">
            <span>
              <i class="fa-solid fa-lightbulb"></i>
            </span>
            <span>Megújuló energiák</span>
          </div>
        </div>
      </div>
      <span className="main-map-title">
        A VASFA kazánok minden régióban, országszerte
      </span>
      <Map />
      <Footer />
    </div>
  );
}
