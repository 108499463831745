import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Rolunk from "./pages/Rolunk";
import Termekek from "./pages/Termekek";
import Gyartas from "./pages/Gyartas";
import Vallalkozasok from "./pages/Vallalkozasok";
import Szolgaltatasok from "./pages/Szolgaltatasok";
import Referenciak from "./pages/Referenciak";
import Kapcsolat from "./pages/Kapcsolat";

import ScrollToTop from "./components/ScrollToTop";
import Referencia from "./pages/Referencia";
import Tanusitvanyok from "./pages/Tanusitvanyok";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rolunk" element={<Rolunk />} />
          <Route path="/termekeink" element={<Termekek />} />
          <Route path="/tanusitvanyaink" element={<Tanusitvanyok />} />
          <Route path="/gyartas" element={<Gyartas />} />
          <Route path="/vallalkozasok" element={<Vallalkozasok />} />
          <Route path="/szolgaltatasok" element={<Szolgaltatasok />} />
          <Route path="/referencia-lista" element={<Referenciak />} />
          <Route path="/referencia/:slug" element={<Referencia />} />
          <Route path="/kapcsolat" element={<Kapcsolat />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
