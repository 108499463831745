import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Termekek() {
  return (
    <div>
      <Header />
      <div className="text-4xl mt-32 mb-12 flex justify-center">
        Termékeink
      </div>
      <div className="product-container">
        <div>
          <p className="product-name">Alacsony nyomású telített gőzt termelő AKG és AKH kazánok</p>
          <p className="product-info">Teljesítménytartomány: 0,15-3 t/h, engedélyezési nyomás: 0,5 bar.</p>
        </div>
        <div>
          <p className="product-name">Közép nyomású telített gőzt termelő AKG és AKH kazánok</p>
          <p className="product-info">Teljesítménytartomány: 0,15-44 t/h, engedélyezési nyomás: 8-22 bar.</p>
        </div>
        <div>
          <p className="product-name">Túlhevített gőzt termelő AKH kazánok</p>
          <p className="product-info">Teljesítménytartomány:  1-25 t/h, engedélyezési nyomás: 8-22 bar.</p>
          <p className="product-info">Teljesítménytartomány: 25-44 t/h, engedélyezési nyomás: 8-14 bar.</p>
        </div>
        <div>
          <p className="product-name">Forróvíz- és melegvíztermelő AKH kazánok</p>
          <p className="product-info">Teljesítménytartomány: 460-23000 kW, engedélyezési nyomás: 8-16 bar.</p>
        </div>
        <div>
          <p className="product-name">Melegvíztermelő VFK kazánok</p>
          <p className="product-info">Teljesítménytartomány: 700-3550 kW, engedélyezési nyomás: 4 bar.</p>
        </div>
        <div>
          <p className="product-name">Tápvízelőmelegítők, egyedi tervezésű bordáscsöves hőcserélők</p>
        </div>
        <div>
          <p className="product-name">Egyedi füstgáz-hőhasznosító gőz- és melegvíztermelő berendezések</p>
        </div>
        <div>
          <p className="product-name">Egyedi tartályok, légtartályok</p>
          <p className="product-info">1-50 m3-es tartományban, 10-15 bar-os nyomástartományban</p>
        </div>
        <div>
          <p className="product-name">Gőz-puffertartályok, Gőzosztók   </p>
        </div>
        <div>
          <p className="product-name">Thermikus gáztalanítós táptartályok</p>
        </div>
        <div>
          <p className="product-name">Kazánkémények</p>
          <p className="product-info">Önhordó vagy szerelt kémények saválló béléssel.</p>
        </div>
        <div>
          <p className="product-name">Egyéb kazánházi és tápházi berendezések</p>
          <p className="product-info">Kondenztartályok, melegvíztároló tartályok, táptartályok, mintahűtők ...stb.</p>
        </div>
        <div>
          <p className="product-name">Egyedi kazánok és nyomástartó berendezések</p>
          <p className="product-info"> Megrendelő által biztosított tervek alapján.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
