import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="footer-distributed">
      <div class="footer-left">
        <h3>
          Vasfa<span> KFT.</span>
        </h3>

        <p class="footer-links">
          <Link to="/rolunk">Rólunk</Link>
          <Link to="/termekeink">Termékeink</Link>
          <Link to="/tanusitvanyaink">Tanúsítványaink</Link>
          <Link to="/gyartas">Gyártás</Link>
          <Link to="/vallalkozasok">Vállalkozások</Link>
          <Link to="/szolgaltatasok">Szolgáltatások</Link>
          <Link to="/referencia-lista">Referencia lista</Link>
          <Link to="/kapcsolat">Kapcsolat</Link>
        </p>

        <p class="footer-company-name">© 2023 Vasfa KFT.</p>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span>5000 Szolnok,</span>Nagysándor József út 35.
          </p>
        </div>

        <div>
          <i class="fa fa-phone"></i>
          <p>+36 56 512 010</p>
        </div>

        <div>
          <i class="fa fa-phone"></i>
          <p>+36 30 406 5710</p>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:vasfa@vasfa.hu">vasfa@vasfa.hu</a>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              class="gmap_iframe"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=1200&amp;height=400&amp;hl=hu&amp;q=Vasfa Szolnoki Kazángyártó KFt.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://gachanox.io/">Gacha Nox</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
