export const companies = [
  {
    name: "Dunapack Kft.",
    city: "Dunavarsány",
    geocode: [47.30773, 19.04688],
    year: 2022,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    img: [
      "/images/references/dunapack-1.jpg",
      "/images/references/dunapack-2.jpg",
      "/images/references/dunapack-3.jpg",
    ],
    reference: "dunapack",
  },
  {
    name: "E.ON",
    city: "Szombathely",
    geocode: [47.23788, 16.62564],
    year: 2021,
    description: "",
    img: ["/images/references/eon.JPG"],
    reference: "e-on",
  },
  {
    name: "Egis Gyógyszergyár Zrt.",
    city: "Budapest",
    geocode: [47.498, 19.2341],
    year: 2018,
    description: "",
    img: [
      "/images/references/egis-1.jpg",
      "/images/references/egis-2.jpg",
      "/images/references/egis-3.jpg",
    ],
    reference: "egis",
  },
  {
    name: "Fűtőmű",
    city: "Szeged",
    geocode: [46.2732, 20.1384],
    year: 2022,
    description: "",
    img: ["/images/references/futomu-szeged.jpg"],
    reference: "futomu-szeged",
  },
  {
    name: "GSK Biologicals Kft.",
    city: "Gödöllő",
    geocode: [47.60129,19.32588
    ],
    year: 0,
    description: "",
    img: ["/images/references/gsk.jpg"],
    reference: "gsk",
  },
  {
    name: "Gyermelyi Holding Zrt.",
    city: "Gyermely",
    geocode: [47.60621,18.64457
    ],
    year: 0,
    description: "",
    img: ["/images/references/gyermely.jpg"],
    reference: "gyermelyi",
  },
  {
    name: "Hell Energy Magyarország Kft.",
    city: "Szikszó",
    geocode: [48.17441,20.91795
    ],
    year: 0,
    description: "",
    img: [
      "/images/references/hell-1.JPG",
      "/images/references/hell-2.JPG",
      "/images/references/hell-3.JPG",
      "/images/references/hell-4.JPG",
    ],
    reference: "hell-energy",
  },
  {
    name: "Huntsman Corporation Hungary Zrt.",
    city: "Pétfürdő",
    geocode: [47.16424,18.13444
    ],
    year: 2019,
    description: "",
    img: [""],
    reference: "huntsman",
  },
  {
    name: "Kischemicals Kft.",
    city: "Sajóbábony",
    geocode: [48.16470,20.71115
    ],
    year: 2023,
    description: "",
    img: [
      "/images/references/kischemicals-1.jpg",
      "/images/references/kischemicals-2.jpg",
      "/images/references/kischemicals-3.jpg",
    ],
    reference: "kischemicals",
  },
  {
    name: "Kőröstej Kft.",
    city: "Kacsóta",
    geocode: [46.03736,17.95636
    ],
    year: 2021,
    description: "",
    img: ["/images/references/korostej-kacsota.JPG"],
    reference: "korostej",
  },
  {
    name: "Márka Üdítőgyártó Kft.",
    city: "Felsőlajos",
    geocode: [47.05909,19.50972
    ],
    year: 2021,
    description: "",
    img: ["/images/references/marka-1.JPG", "/images/references/marka-2.JPG"],
    reference: "marka",
  },
  {
    name: "Masterplast Hungária Kft.",
    city: "Kál",
    geocode: [47.71956,20.25599
    ],
    year: 2023,
    description: "",
    img: [
      "/images/references/masterplast-1.jpg",
      "/images/references/masterplast-2.jpg",
    ],
    reference: "masterplast",
  },
  {
    name: "Natural Proteins Kft.",
    city: "Zagyvarékas",
    geocode: [47.27692,20.11087
    ],
    year: 2022,
    description: "",
    img: [
      "/images/references/np-1.jpg",
      "/images/references/np-2.jpg",
      "/images/references/np-3.jpg",
      "/images/references/np-4.jpg",
      "/images/references/np-5.jpg",
    ],
    reference: "natural-proteins",
  },
  {
    name: "Nissin Foods Kft.",
    city: "Kecskemét",
    geocode: [46.88691,19.71638
    ],
    year: 2018,
    description: "",
    img: [
      "/images/references/nissin-1.jpg",
      "/images/references/nissin-2.jpg",
      "/images/references/nissin-3.jpg",
      "/images/references/nissin-4.jpg",
    ],
    reference: "nissin-foods",
  },
  {
    name: "Partner in Pet Food Hungária Kft.",
    city: "Sopronhorpács",
    geocode: [47.48412,16.73002
    ],
    year: 2018,
    description: "",
    img: ["/images/references/ppf-1.jpg", "/images/references/ppf-2.jpg"],
    reference: "ppf",
  },
  {
    name: "Richter Gedeon Nyrt.",
    city: "Debrecen",
    geocode: [47.52225,21.57169
    ],
    year: 2023,
    description: "",
    img: [
      "/images/references/richter-1.jpg",
      "/images/references/richter-2.jpg",
    ],
    reference: "richter",
  },
  {
    name: "Seiren Hungary Kft.",
    city: "Pécs",
    geocode: [46.01172,18.22279
    ],
    year: 2022,
    description: "",
    img: ["/images/references/seiren-1.jpg", "/images/references/seiren-2.jpg"],
    reference: "seiren",
  },
  {
    name: "Takenaka",
    city: "Nyergesújfalu",
    geocode: [47.75766,18.58179
    ],
    year: 2020,
    description: "",
    img: [
      "/images/references/takenaka-1.JPG",
      "/images/references/takenaka-2.JPG",
      "/images/references/takenaka-3.JPG",
      "/images/references/takenaka-4.JPG",
    ],
    reference: "takenaka",
  },
  {
    name: "Univer",
    city: "Kecskemét",
    geocode: [46.91265,19.71203
    ],
    year: 2022,
    description: "",
    img: ["/images/references/univer.JPG"],
    reference: "univer",
  },
  {
    name: "Alföldi Tej",
    city: "Székesfehérvár",
    geocode: [47.18575,18.45601
    ],
    year: 2018,
    description: "",
    img: ["/images/references/alfoldi-tej.jpg"],
    reference: "alfoldi-tej",
  },
  {
    name: "Hübner-H Gumi és Műanyagipari Kft.",
    city: "Nyíregyháza",
    geocode: [47.93150,21.73261
    ],
    year: 2018,
    description: "",
    img: ["/images/references/hubner.jpg"],
    reference: "hubner",
  },
  {
    name: "Fűtőmű",
    city: "Hajdúszoboszló",
    geocode: [47.4421,21.3927],
    year: 2019,
    description: "",
    img: ["/images/references/futomu-hajduszoboszlo.jpg"],
    reference: "futomu-hajduszoboszlo",
  },
  {
    name: "Hungrana Kft.",
    city: "Szabadegyháza",
    geocode: [47.0855,18.7153
    ],
    year: 2019,
    description: "",
    img: [
      "/images/references/hungrana-1.jpg",
      "/images/references/hungrana-2.jpg",
    ],
    reference: "hungrana",
  },
  {
    name: "Komáromi Sajt",
    city: "Komárom",
    geocode: [47.69455,18.10434],
    year: 2019,
    description: "",
    img: ["/images/references/komaromi-sajt.jpg"],
    reference: "komaromi-sajt",
  },
  {
    name: "Ganz Danubius Hungarosteel Kft.",
    city: "",
    geocode: [47.53354,19.03861
    ],
    year: 2020,
    description: "",
    img: ["/images/references/gdhs.JPG"],
    reference: "gdhs",
  },
  {
    name: "Gloster Kft.",
    city: "Máriapócs",
    geocode: [47.85462,22.01447
    ],
    year: 2020,
    description: "",
    img: [
      "/images/references/gloster-1.JPG",
      "/images/references/gloster-2.JPG",
    ],
    reference: "gloster",
  },
  {
    name: "Naszálytej Zrt.",
    city: "Vác",
    geocode: [47.77905,19.14631
    ],
    year: 2020,
    description: "",
    img: ["/images/references/naszalytej.JPG"],
    reference: "naszalytej",
  },
  {
    name: "AKSD",
    city: "Románia",
    geocode: [46.4688,24.4122
    ],
    year: 2021,
    description: "",
    img: ["/images/references/aksd.jpg"],
    reference: "aksd",
  },
  {
    name: "Aqua Lorenzo Kft.",
    city: "Albertirsa",
    geocode: [47.2197,19.6062
    ],
    year: 2021,
    description: "",
    img: [""],
    reference: "aqua-lorenzo",
  },
  {
    name: "Her-Csi-Hús Kft.",
    city: "Hernád",
    geocode: [47.17603,19.41726
    ],
    year: 2021,
    description: "",
    img: ["/images/references/hercsi-1.JPG", "/images/references/hercsi-2.JPG"],
    reference: "her-csi-hus",
  },
  {
    name: "Hirsch Porozell Kft.",
    city: "Jászfényszaru",
    geocode: [47.58273,19.74424
    ],
    year: 2021,
    description: "",
    img: ["/images/references/hirsch.jpg"],
    reference: "hirsch",
  },
  {
    name: "Kenyérgyár Nyíregyháza",
    city: "Nyíregyháza",
    geocode: [47.95135,21.74386
    ],
    year: 2021,
    description: "",
    img: ["/images/references/kenyergyar-nyiregyhaza.JPG"],
    reference: "kenyergyar-nyiregyhaza",
  },
  {
    name: "Kőröstej Kft. (2)",
    city: "Barcs",
    geocode: [47.30773, 19.04688],
    year: 2021,
    description: "",
    img: ["/images/references/korostej-barcs.JPG"],
    reference: "korostej-2",
  },
  {
    name: "Kenyérgyár Kisvárda",
    city: "Kisvárda",
    geocode: [48.22678,22.08930
    ],
    year: 2022,
    description: "",
    img: ["/images/references/kenyergyar-kisvarda.JPG"],
    reference: "kenyergyar-kisvarda",
  },
  {
    name: "Maspex Olympos Kft.",
    city: "Nyárlőrinc",
    geocode: [46.86517,19.87616
    ],
    year: 2022,
    description: "",
    img: ["/images/references/maspex.JPG"],
    reference: "maspex",
  },
  {
    name: "Bonduelle Central Europe Kft.",
    city: "Nyírszőlős",
    geocode: [48.03780,21.67420
    ],
    year: 2023,
    description: "",
    img: [
      "/images/references/bonduelle-1.jpg",
      "/images/references/bonduelle-2.jpg",
    ],
    reference: "bonduelle",
  },
  {
    name: "Jásztej Kft.",
    city: "Miskolc",
    geocode: [48.10330,20.70466
    ],
    year: 2023,
    description: "",
    img: ["/images/references/jasztej.jpg"],
    reference: "jasztej",
  },
];
