import React from 'react'

import "leaflet/dist/leaflet.css"
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import { Icon } from 'leaflet'
import { Link } from 'react-router-dom'
import { markers } from '../markers'
import { companies } from '../companies'




export default function Map() {
  const icon = new Icon({
    iconUrl: '/images/location-pin.png',
    iconSize: [38, 38]
  });

  return (
    <div className='map-container'>
  <MapContainer className='mapcontainer' center={[47.033, 19.688]} zoom={7}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
    />

    {companies.map((marker) => (
      <Marker position={marker.geocode} icon={icon}>
        <Popup className='custom-popup'>
          <Link to={`/referencia/${marker.reference}`}>
            <div className='reference-name-on-map'>{marker.name}</div>
            <div className='reference-img-on-map' style={{ backgroundImage: `url(${marker.img[0]})` }}></div>
          </Link>
        </Popup>
      </Marker>
    ))}
  </MapContainer>
</div>
  );
}