import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Kapcsolat() {
  return (
    <div>
      <Header />
      <div>
        <div className="mapouter2">
          <div className="gmap_canvas2">
            <iframe
              class="gmap_iframe2"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=1200&amp;height=400&amp;hl=hu&amp;q=Vasfa Szolnoki Kazángyártó KFt.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://gachanox.io/">Gacha Nox</a>
          </div>
        </div>
        <div className="contacts-container">
          <div className="contacts-header">
            <span>
              <strong>Lépjen kapcsolatba velünk!</strong>
            </span>
          </div>
          <div className="contacts-main">
            <span>
              <strong className="contacts-title">Cím:</strong> 5000 Szolnok,
              Nagysándor József út 35.
            </span>
            <span>
              <strong className="contacts-title">Telefon:</strong> +36 56 512
              010
            </span>
            <span>
              <strong className="contacts-title">Mobil:</strong> +36 30 406 5710
            </span>
            <span>
              <strong className="contacts-title">Főmérnök:</strong> +36 56 512
              011
            </span>
            <span>
              <strong className="contacts-title">Üzemvezető:</strong> +36 56 512
              012
            </span>
            <span>
              <strong className="contacts-title">
                Minőségirányítási osztály:
              </strong>{" "}
              +36 56 512 013
            </span>
            <span>
              <strong className="contacts-title">
                Anyaggazdálkodási osztály:
              </strong>{" "}
              +36 56 512 014
            </span>
            <span>
              <strong className="contacts-title">Műszaki osztály:</strong> +36
              56 512 017
            </span>
            <span>
              <strong className="contacts-title">
                Ügyvezető, kereskedelmi osztály:
              </strong>{" "}
              +36 56 512 022
            </span>
            <span>
              <strong className="contacts-title">Titkárság:</strong> +36 56 512
              026
            </span>
            <span>
              <strong className="contacts-title">E-mail:</strong>{" "}
              <a href="mailto:vasfa@vasfa.hu">vasfa@vasfa.hu</a>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
