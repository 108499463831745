import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { companies } from "../companies";
import { Link } from "react-router-dom";

export default function Referenciak() {
  return (
    <div>
      <Header />
      <div className="reference-container">
        {companies.map((company, index) => (
          <div key={index} className="company-card">
            <Link to={`/referencia/${company.reference}`}>
              <div className="company-name">{company.name}</div>
              <div className="company-location">
                <i class="fa-sharp fa-solid fa-location-dot text-red-500"></i>
                <p className="company-city">
                  {company.city}, {company.year}
                </p>
              </div>
              <div className="w-[300px] h-[300px]">
              <div
                style={{ backgroundImage: `url(${company.img[0]})` }}
                className="w-full h-full rounded-2xl bg-center bg-cover"
              ></div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
