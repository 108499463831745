import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header class="header-container">
      <Link to="/">
        <img id="logo" src="/images/logo.png" width="100" alt="Logo" />
      </Link>
      <ul class="flex space-x-8">
        <li>
          <Link to="/rolunk">Rólunk</Link>
        </li>
        <li>
          <Link to="/termekeink">Termékeink</Link>
        </li>
        <li>
          <Link to="/tanusitvanyaink">Tanúsítványaink</Link>
        </li>
        <li>
          <Link to="/gyartas">Gyártás</Link>
        </li>
        <li>
          <Link to="/vallalkozasok">Vállalkozások</Link>
        </li>
        <li>
          <Link to="/szolgaltatasok">Szolgáltatások</Link>
        </li>
        <li>
          <Link to="/referencia-lista">Referencia lista</Link>
        </li>
        <li>
          <Link to="/kapcsolat">Kapcsolat</Link>
        </li>
      </ul>
      <div class="flex gap-2">
        <i class="flag-icon flag-icon-hu text-xl"></i>
        <i class="flag-icon flag-icon-gb text-xl"></i>
      </div>
    </header>
  );
}
